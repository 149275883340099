import { Injectable } from '@angular/core';
import { environment } from '@env';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CloseService {
  public closeCP$: Observable<boolean>;
  private closeCP: BehaviorSubject<boolean>;
  constructor() {
    this.closeCP = new BehaviorSubject<boolean>(false);

    if (environment.closeCPDate) {
      const currentDate = new Date();
      const closeDate = new Date(environment.closeCPDate);
      if (currentDate > closeDate) this.closeCP.next(true);
    }

    this.closeCP$ = this.closeCP.asObservable();
  }

  public get closeCPVal(): boolean {
    return this.closeCP.value;
  }

  public checkClose() {
    if (environment.closeCPDate) {
      const closeDate = new Date(environment.closeCPDate);
      interval(1000)
        .pipe(takeWhile(() => new Date() <= closeDate))
        .subscribe({
          next: () => {
            this.closeCP.next(false);
          },
          complete: () => {
            this.closeCP.next(true);
          },
        });
    }
  }
}
